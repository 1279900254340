@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-black;
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
}


@keyframes fadein{
	0%{
		opacity: 0;
	}
	50%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

body { 
	background: $color-black;
	color: $color-white;
	font-family: $primary-font, sans-serif;
	line-height: 1.5;
	width: 100%;
	font-weight: 500;
	overflow-x: hidden;
	animation: fadein 1.5s linear forwards;
}


a {
	color: inherit;
	text-decoration: none;
	transition: .3s all;
	@include hover-focus {
		opacity: .8;
	}
}

#bg{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	img,video{
		position: absolute;
		object-fit: cover;
		width:100%;
		height: 100%;
		transition: opacity .3s;
	}
	&:not(.home){
		video{
			opacity: 0;
		}
	}
}

.page-wrap{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	// #main{
	// 	flex: 1;
	// }
}

#header {
	padding: rems(30) 0;
	position: relative;
	font-size: rems(22);
	h1{
		margin: 0;
	}
	.container{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	nav a{
		text-decoration: none;
		text-transform: uppercase;
		margin-left: 2em;
	}
	.menu-toggle{
		display: none;
	}
	@media screen and (max-width: 800px){
		nav{
			position: absolute;
			top: rems(-300);
			right: 0;
			left: 0;
			opacity: 0;
			z-index: 9;
			pointer-events: none;
			background-color: $color-black;
			color: $color-white;
			display: flex;
			flex-direction: column;
			gap: rems(20);
			padding: rems(150) rems(30);
			text-align: center;
			transition: .3s all;
			a{
				margin: 0;
			}
			&.open{
				pointer-events: all;
				top: 0;
				opacity: 1;
			}
		}
		.menu-toggle{
			display: block;
			position: relative;
			z-index: 10;
		}
	}
}

#main {
	padding: rems(20) 0 rems(80);
	position: relative;
	text-align: center;
	.btn{
		font-size: rems(22);
		padding: .3em 1.5em;
		text-transform: uppercase;
		background-color: $color-black;
		color: $color-white;
		border: 2px solid $color-black;
		display: inline-block;
		@include hover-focus{
			background-color: transparent;
			border-color: $color-white;
		}
	}

	#home{
		.container{
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
		.col{
			width: rems(400);
			max-width: 100%;
			p{
				margin: 0;
				text-transform: uppercase;
				font-size: rems(22);
				&+p{
					font-size: rems(32);
					&+p{
						margin: .5em 0;
						font-size: rems(40);
					}
				}
			}
			img{
				margin: rems(30) 0;
			}
		}
	}
	#music{
		.container{
			max-width: rems(1200);
		}
		.item{
			display: flex;
			align-items: center; 
			@media screen and (max-width: 980px){
				flex-direction: column;
				align-items: center;
			}
			&+.item{
				margin-top: rems(200);
				
				@media screen and (max-width: 980px){
					margin-top: rems(100);
				}
			}

			img{
				width: rems(455);
				max-width: 100%;
			}
			
			.info{
				flex: 1;
				padding: rems(30);
				text-transform: uppercase;
				@media screen and (max-width: 980px){
					padding: rems(15) 0;
				}
				p{
					font-size: rems(35);
					@media screen and (max-width: 980px){
						font-size: rems(20);
					}
					span{
						display: inline-block;
					}
				}
			}
		}
	}
	#tour{
		.container{
			max-width: rems(1260);
			
			@media screen and (max-width: 960px){
				max-width: rems(400);
			}
		}
		.event{
			display: flex;
			align-items: flex-start;
			gap: rems(10);
			text-transform: uppercase;
			text-align: left;
			@media screen and (max-width: 960px){
				flex-direction: column;
				align-items: center;
				text-align: center;
				gap: 0;
			}
			&+.event{
				margin-top: rems(30);
			}
			.event-date{
				flex: .5;
				font-size: rems(22);
			}
			.event-venue, .event-location{
				flex: 2;
				font-family: $body-font;
				font-size: rems(18);
			}
			.event-links{
				flex: 1;
				display: flex;
				gap: rems(15);
				.btn{
					font-size:rems(18);
					padding: .3em 1em;
				}
			}
		}
	}
	#video{
		.container{
			max-width: rems(760);
			p{
				font-size: rems(31);
				text-transform: uppercase;
				@media screen and (max-width: 980px){
					font-size: rems(20);
				}
			}
			.item+.item{
				margin: rems(200) 0;
				
				@media screen and (max-width: 980px){
					margin: rems(100) 0;
				}
			}
		}
	}
	#about{
		.container{
			max-width: rems(760);
		}
		h2{
			font-size: rems(32);
			text-transform: uppercase;
		}
		p{
			font-family: $body-font;
			text-transform: uppercase;
		}
	}
	
	#signup{
		svg{
			width: 100%;
			max-width: rems(400);
			margin: 0 auto;
		}
		.inputs-wrap{
			max-width: 100%;
			display: flex;
			gap: rems(10);
			font-size: rems(22);
			justify-content: center;
		}
		input{
			background: transparent;
			flex: 1;
			width: 100%;
			max-width: rems(500);
			border: none;
			border-bottom: 2px solid $color-white;
			color: $color-white;
			font-size: rems(22);
			padding: .3em 0;
			&::placeholder{
				color: $color-white;
				opacity: .8;
			}
		}
		form+.btn{
			margin-top: rems(100);
		}
	}
}

#footer {
	position: relative;
	font-family: $body-font;
	text-transform: uppercase;
	font-size: rems(10);
	text-align: center;
}